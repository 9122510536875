import React, { useEffect, useState } from 'react';
import axios from 'axios';

/*
https://kinesis.us-east-1.amazonaws.com/
https://cognito-identity.us-east-1.amazonaws.com/
https://cognito-idp.us-east-1.amazonaws.com/

https://api.mystudy.altavozclinical.com/
https://mystudyapi.altavozclinical.com/
*/

enum Contact {
  Loading = 'Loading',
  Error = 'Error',
  Success = 'Success',
}

type DateSkew = {
  serviceDate: Date;
  localDate: Date;
};

function App() {
  const [canContact, setCanContact] = useState({
    kinesis: Contact.Loading,
    kinesisWebClient: Contact.Loading,
    cognitoIdp: Contact.Loading,
    cognitoIdenity: Contact.Loading,
  });

  const [dateSkew, setDateSkew] = useState<Record<string, null | DateSkew>>({
    kinesis: null,
    cognitoIdp: null,
    cognitoIdenity: null,
  });

  function makeSkew(service: string, dateHeader: string) {
    if (!dateHeader) return;
    
    setDateSkew((sk) => ({
      ...sk,
      [service]: {
        serviceDate: new Date(dateHeader),
        localDate: new Date(),
      },
    }));
  }

  function renderSkew(ds: DateSkew) {
    const format = new Intl.DateTimeFormat(undefined, {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      timeZoneName: 'short'
    });

    const timeDiff = Math.abs(ds.serviceDate.valueOf() - ds.localDate.valueOf())
    const color = timeDiff > 5 * 1000 ? 'red' : undefined;

    return (
      <span style={{ color }}>
        <strong>Service: </strong> {format.format(ds.serviceDate)}
        {' / '}
        <strong>Local: </strong> {format.format(ds.localDate)}
      </span>
    );
  }

  useEffect(() => {
    async function go() {
      try {
        const d = await axios.get('https://kinesis.us-east-1.amazonaws.com/?Action=PutRecord', {
          timeout: 10000,
        });
        setCanContact((v) => ({
          ...v,
          kinesis: d.headers['x-amzn-requestid'] ? Contact.Success : Contact.Error,
        }));
        makeSkew('kinesis', d.headers['date']);

        console.log({ d });
      } catch (e) {
        setCanContact((v) => ({
          ...v,
          kinesis: e.response?.headers['x-amzn-requestid'] ? Contact.Success : Contact.Error,
        }));
        makeSkew('kinesis', e.response?.headers['date']);
        const reqid = e.response?.headers['x-amzn-requestid'];
        console.log('kinesis', { reqid });
      }
    }
    go();
  }, []);
  useEffect(() => {
    async function go() {
      try {
        const d = await axios.post(
          'https://cognito-identity.us-east-1.amazonaws.com/',
          {
            IdentityPoolId: 'us-east-1:29215d25-e61d-4ec7-95ab-3b04b2bbb2aa',
          },
          {
            headers: {
              'content-type': 'application/x-amz-json-1.1',
              'x-amz-target': 'AWSCognitoIdentityService.GetId',
            },
            timeout: 10000,
          }
        );
        console.log('identity', { d });
        setCanContact((v) => ({
          ...v,
          cognitoIdenity: d.headers['x-amzn-requestid'] ? Contact.Success : Contact.Error,
        }));
        makeSkew('cognitoIdenity', d.headers['date']);
      } catch (e) {
        setCanContact((v) => ({
          ...v,
          cognitoIdenity: e.response?.headers['x-amzn-requestid'] ? Contact.Success : Contact.Error,
        }));
        makeSkew('cognitoIdenity', e.response?.headers['date']);
        const reqid = e.response?.headers['x-amzn-requestid'];
        console.log('identity', { reqid });
      }
    }
    go();
  }, []);
  useEffect(() => {
    async function go() {
      try {
        const d = await axios.post(
          'https://cognito-idp.us-east-1.amazonaws.com/',
          {},
          {
            headers: {
              'content-type': 'application/x-amz-json-1.1',
              'x-amz-target': 'AWSCognitoIdentityProviderService.InitiateAuth',
            },
            timeout: 10000,
          }
        );
        setCanContact((v) => ({
          ...v,
          cognitoIdp: d.headers['x-amzn-requestid'] ? Contact.Success : Contact.Error,
        }));
        makeSkew('cognitoIdp', d.headers['date']);
        console.log({ d });
      } catch (e) {
        setCanContact((v) => ({
          ...v,
          cognitoIdp: e.response?.headers['x-amzn-requestid'] ? Contact.Success : Contact.Error,
        }));
        makeSkew('cognitoIdp', e.response?.headers['date']);
        const reqid = e.response?.headers['x-amzn-requestid'];
        console.log('idp', { reqid });
      }
    }
    go();
  }, []);
  // useEffect(() => {
  //   async function go() {
  //     try {
  //       const d = await axios.get('https://kinesis.mystudy.iqviadiary.com/', {
  //         timeout: 10000,
  //       });
  //       console.log({ d });
  //       setCanContact((v) => ({
  //         ...v,
  //         kinesisWebClient: d.headers['x-amzn-requestid'] ? Contact.Success : Contact.Error,
  //       }));
  //     } catch (e) {
  //       console.log({ e });
  //       setCanContact((v) => ({
  //         ...v,
  //         kinesisWebClient: e.response?.headers['x-amzn-requestid'] ? Contact.Success : Contact.Error,
  //       }));
  //       const reqid = e.response?.headers['x-amzn-requestid'];
  //       console.log('kinesis', { reqid });
  //     }
  //   }
  //   go();
  // }, []);
  /*
  navigator.connection
  user agent
  local storage / session storage
  downlink: 10
  effectiveType: "4g"
  onchange: null
  rtt: 0
  saveData: false*/
  return (
    <div className="App" style={{ padding: 16 }}>
      <table className="table table-striped table-bordered table-sm">
        <tbody>
          <tr>
            <th>Mobile Kinesis</th>
            <td>{canContact.kinesis} {' :: '} {dateSkew.kinesis ? renderSkew(dateSkew.kinesis) : 'N/A'}</td>
          </tr>
          <tr>
            <th>Cognito IDP</th>
            <td>{canContact.cognitoIdp} {' :: '} {dateSkew.cognitoIdp ? renderSkew(dateSkew.cognitoIdp) : 'N/A'}</td>
          </tr>
          <tr>
            <th>Cognito Identity</th>
            <td>{canContact.cognitoIdenity} {' :: '} {dateSkew.cognitoIdenity ? renderSkew(dateSkew.cognitoIdenity) : 'N/A'}</td>
          </tr>
          <tr>
            <th>Time Zone</th>
            <td>{Intl.DateTimeFormat().resolvedOptions().timeZone}</td>
          </tr>
          <tr>
            <th>Language</th>
            <td>{navigator.language}</td>
          </tr>
          <tr>
            <th>Device Memory</th>
            <td>{(navigator as any).deviceMemory}</td>
          </tr>
          <tr>
            <th>Platform</th>
            <td>{navigator.platform}</td>
          </tr>
          <tr>
            <th>Device Pixel Ratio</th>
            <td>{window.devicePixelRatio}</td>
          </tr>
          <tr>
            <th>Screen Width</th>
            <td>{window.screen.width}</td>
          </tr>
          <tr>
            <th>Screen Avail Width</th>
            <td>{window.screen.availWidth}</td>
          </tr>
          <tr>
            <th>Screen Height</th>
            <td>{window.screen.height}</td>
          </tr>
          <tr>
            <th>Screen Avail Height</th>
            <td>{window.screen.availHeight}</td>
          </tr>
          <tr>
            <th>Inner Width</th>
            <td>{window.innerWidth}</td>
          </tr>
          <tr>
            <th>Inner Height</th>
            <td>{window.innerHeight}</td>
          </tr>
        </tbody>
        {/* <tr>
          <th>Visual Viewport</th>
          <td>
            {JSON.stringify(
              {
                height: window.visualViewport.height,
                offsetLeft: window.visualViewport.offsetLeft,
                offsetTop: window.visualViewport.offsetTop,
                pageLeft: window.visualViewport.pageLeft,
                pageTop: window.visualViewport.pageTop,
                scale: window.visualViewport.scale,
                width: window.visualViewport.width,
              },
              null,
              2
            )}
          </td>
        </tr> */}
      </table>
    </div>
  );
}

export default App;
